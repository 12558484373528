<template>
  <div class="exporter">
    <MobileMenu />
    <div class="df jc-sb ai-c">
      <div class="p-t-10 p-b-10">
        <Icon
          name="LogoPortTranzit"
          :width="isMobile ? '200px' : '285px'"
          height="auto"
        />
      </div>

      <el-menu v-if="!isMobile" mode="horizontal" class="exporter__navigation">
        <el-menu-item
          v-for="item in options"
          :key="item.id"
          :class="{ 'is-active': item.id === activePageId }"
          @click.native="changePage(item)"
        >
          <Icon :name="item.icon" />
          {{ item.title }}
        </el-menu-item>

        <el-menu-item @click="$emit('logout')">
          <Icon name="IconGoToTerminal" width="18px" height="18px" />
          Выход
        </el-menu-item>
      </el-menu>

      <div v-else class="exporter__mobileButton">
        <Icon
          name="IconMenuLine"
          width="44"
          height="auto"
          @click.native="handleOpenMobileMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE, SET_DRAWER } from '@/store/actions'
import { MAIN_MENU } from '@/constants/drawers'
import { mapGetters, mapMutations } from 'vuex'
import { navigationExporterList } from '@/constants/navigaiton'
import Icon from '@/UI/icon/Icon'
import MobileMenu from '@/components/drawer/mobile-menu/MobileMenu'

export default {
  name: 'ExporterHeaderTop',
  components: { Icon, MobileMenu },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activePageId: 0,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
  watch: {
    $route: {
      handler() {
        this.setActivePage()
      },
      deep: true,
    },
  },
  mounted() {
    this.setActivePage()
  },
  methods: {
    ...mapMutations({ setDrawer: SET_DRAWER }),
    setActivePage() {
      this.activePageId = this.options.find(
        item => item.name === this.$route.name,
      ).id
    },
    changePage(page) {
      this.$emit('goToPage', page.name)
    },
    handleOpenMobileMenu() {
      this.setDrawer({
        name: MAIN_MENU,
        visible: true,
        data: {
          items: navigationExporterList,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.exporter
  min-height: 60px
  padding: 0 20px
  background: $color-blue-primary

  &__navigation
    display: flex
    align-items: center
    justify-content: flex-end
    border: none
    background: transparent

    .el-menu-item
      color: $color-white
      display: flex
      align-items: center
      font-size: 16px
      font-weight: bold
      margin: 0
      padding: 0 32px

      svg
        margin-right: 5px

      &:hover,
      &:focus,
      &.is-active
        background-color: #50B1DB
        color: $color-white

    .el-menu-item + .el-menu-item
      border-left: 1px solid rgba(255, 255, 255, 0.3)

  &__mobileButton
    cursor: pointer
</style>
